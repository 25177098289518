<template>
  <b-modal
    ref="modal-vat-invoice-excel"
    hide-footer
    :title="titleExcel"
    id="modal-vat-invoice-excel"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <b-form-group label="Danh sách cần xuất:">
          <b-form-radio-group v-model="selectedListExcel">
            <b-form-radio value="all">Tất cả các trang</b-form-radio>
            <b-form-radio value="filter">Theo bộ lọc</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-row v-if="selectedListExcel === 'all'">
          <b-col md="4">
            <b-form-group>
              <label>Từ ngày tạo:</label>
              <date-picker
                placeholder="Từ ngày tạo"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.fromUpdatedDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Đến ngày tạo:</label>
              <date-picker
                placeholder="Đến ngày tạo"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="apiParams.toUpdatedDate"
              ></date-picker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      :show="isDownloading"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button variant="primary" size="sm" @click="exportToExcelStream">
        <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
        <strong>Xuất Excel</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('modal-vat-invoice-excel')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<script>
import moment from 'moment';

import { validationMixin } from 'vuelidate';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { makeToastFaile } from '@/utils/common';
import { BASE_URL } from '@/utils/constants';
import axios from 'axios';
import fileDownload from '@/utils/file-download';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import timeUtils from '@/utils/date';

export default {
  props: ['apiParams', 'mode'],
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: timeUtils.DP_CONFIG,
      selectedListExcel: 'all',
      titleExcel: 'Xuất excel hoá đơn đỏ',
      isDownloading: false,
    };
  },
  components: {
    datePicker,
  },
  computed: {},
  created() {},
  validations: {},
  methods: {
    checkPermissions,
    exportToExcelStream() {
      this.isDownloading = true;

      const baseUrl = BASE_URL;

      const apiParams = this.getParamFilters();

      if (!apiParams) {
        this.isDownloading = false;
        return;
      }

      axios
        .get(`${baseUrl}vat-bills/excel`, {
          params: {
            ...apiParams,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const fileName = fileDownload.getFileName(response);
          fileDownload.createFile(response.data, fileName);
          this.isDownloading = false;
        })
        .catch((err) => {
          this.isDownloading = false;
          if (!err.response) {
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }

          this.$nprogress.done();
        });
    },
    getValuesMultiSelect(arr, prop) {
      return arr
        ? arr.reduce((accumulator, currentValue) => {
            if (currentValue.checked) accumulator.push(currentValue[prop]);
            return accumulator;
          }, [])
        : [];
    },
    getParamFilters() {
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';

      const fromUpdatedDate = this.apiParams.fromUpdatedDate
        ? moment(this.apiParams.fromUpdatedDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : '1991-01-01';
      const toUpdatedDate = this.apiParams.toUpdatedDate
        ? moment(this.apiParams.toUpdatedDate, DATE_FORMAT).format(DATE_DB_FORMAT)
        : moment().format(DATE_DB_FORMAT);

      const apiParams = {
        fromUpdatedDate,
        toUpdatedDate
      };

      if (this.selectedListExcel !== 'filter') {
        const diffUpdatedDate = this.getDaysDiff(apiParams.fromUpdatedDate, apiParams.toUpdatedDate);
        if (diffUpdatedDate > 100) {
          makeToastFaile('Vui lòng chọn khoảng thời gian trong vòng 3 tháng!');
          return null;
        }
        return apiParams;
      }

      let storeIds = this.apiParams.storeIds.map((item) => item.id);
      if (storeIds.includes(-1)) {
        storeIds = [];
      }
      return {
        ...this.apiParams,
        ...apiParams,
        storeIds,
      };
    },
    getDaysDiff(startDate, endDate, dateFormat = 'YYYY-MM-DD') {
      const getDateAsArray = (date) => {
        return moment(date.split(/\D+/), dateFormat);
      };
      return (
        getDateAsArray(endDate).diff(getDateAsArray(startDate), 'days') + 1
      );
    },
  },
};
</script>
